import React, { useState } from "react";
import Form, { SimpleItem, GroupItem, ButtonItem } from "devextreme-react/form";
import { alert } from "devextreme/ui/dialog";
import AccountService from "../../service/accountsService";
import { showNotification } from "../../utils/notification";
import { LoadPanel, Popup, NumberBox, Button } from "devextreme-react";
import { jobStatusArray } from "../../constants/data";
import JobDataTable from "./JobDataTable";
import JobService from "../../service/jobService";
import { useAuth } from "../../contexts/auth";

export default function ManageJobs() {
  const [formData] = useState({
    jobCode: "",
    status: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [jobsData, setJobData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [paymentMade, setPaymentMade] = useState(null);
  const [jobInfo, setJobInfo] = useState({
    jobId: "",
    invoiceAmount: 0,
  });

  const { token } = useAuth();

  const confirmPayment = async () => {
    try {
      setIsLoading(true);
      await JobService.confirmPayment(jobInfo.jobId, paymentMade, token);
      showNotification("Payment confirmed", "success");
      setIsLoading(false);
      handleClose();
      getAllJobs(currentPage);
    } catch (error) {
      showNotification(error);
    }
  };

  const approveJob = async (jobId) => {
    try {
      setIsLoading(true);
      await JobService.approveJob(jobId, token);
      showNotification("Job approved successfully", "success");
      setIsLoading(false);
      getAllJobs(currentPage);
    } catch (error) {
      showNotification(error);
    }
  };

  const handleConfirmPayment = (info) => {
    setJobInfo(info);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setPaymentMade("");
    setJobInfo({
      jobId: "",
      invoiceAmount: 0,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!formData.jobCode && !formData.status) {
      alert("Please apply at least one filter", "Notification");
      return;
    }

    getAllJobs();
  };

  const getAllJobs = async (page) => {
    try {
      setIsLoading(true);

      const filters = {
        page: page ? page + 1 : 1,
        status: formData.status,
      };

      if (formData.status === "Yes") {
        filters.isTaskApproved = true;
        filters.status = "";
      }

      if (formData.status === "No") {
        filters.isTaskApproved = false;
        filters.status = "";
      }

      const {
        data: { data },
      } = await AccountService.getAllJobs(
        {
          ...filters,
        },
        token
      );
      if (page || page === 0) {
        setCurrentPage(page);
      }
      setJobData(data);

      setIsLoading(false);
    } catch (error) {
      showNotification(error, "error");
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <LoadPanel visible />}
      <form className={"content-block"} onSubmit={onSubmit}>
        <h6 className='mb-3'>Apply Filters</h6>
        <Form labelMode='floating' formData={formData} disabled={isLoading}>
          <GroupItem colCount={2}>
            <SimpleItem
              dataField='status'
              editorType='dxSelectBox'
              editorOptions={{
                dataSource: jobStatusArray,
                displayExpr: "display",
                valueExpr: "value",
                searchEnabled: true,
                showClearButton: true,
              }}
            />
            <SimpleItem
              dataField='jobCode'
              editorOptions={{ showClearButton: true }}
            />
          </GroupItem>
          <ButtonItem
            horizontalAlignment='left'
            buttonOptions={{
              text: "Search",
              type: "default",
              useSubmitBehavior: true,
            }}
          />
        </Form>
      </form>
      {jobsData && jobsData.docs ? (
        <div className={"content-block dx-card responsive-paddings !mt-8"}>
          <JobDataTable
            dataSource={jobsData.docs}
            currentPage={currentPage}
            status={
              formData.status
                ? formData.status
                : formData.jobCode
                ? jobsData.docs[0].status
                : ""
            }
            totalPages={jobsData.totalPages}
            setCurrentPage={(page) => {
              getAllJobs(page);
            }}
            confirmPayment={handleConfirmPayment}
            getAllJobs={() => getAllJobs(currentPage)}
            approveJob={approveJob}
          />
        </div>
      ) : null}

      <Popup
        visible={isOpen}
        onHiding={handleClose}
        disabled={isLoading}
        title='Payment Amount'
        showCloseButton
      >
        <div>
          <span className='mb-3 inline-block'>
            <strong>Invoice amount:</strong> {jobInfo.invoiceAmount}
          </span>
          <NumberBox
            label='Amount paid'
            labelMode='floating'
            min={1}
            value={paymentMade}
            onValueChanged={({ value }) => setPaymentMade(value)}
            valueChangeEvent='keyup'
            showSpinButtons
            showClearButton
          />
          <small>Please enter amount paid by employer</small>

          <div className='mt-4'>
            <Button
              text='Confirm payment'
              type='default'
              disabled={!paymentMade}
              onClick={confirmPayment}
            />
          </div>
        </div>
      </Popup>
    </>
  );
}
