import React from "react";
import {
  Pagination as HeadlessPagination,
  PrevButton,
  NextButton,
  PageButton,
} from "react-headless-pagination";

export default function Pagination({
  currentPage,
  setCurrentPage,
  totalPages,
}) {
  return (
    <HeadlessPagination
      className='flex items-center w-full h-10 text-sm select-none'
      currentPage={currentPage}
      edgePageCount={2}
      middlePagesSiblingCount={1}
      setCurrentPage={setCurrentPage}
      totalPages={totalPages}
      truncableClassName='w-10 px-0.5 text-center'
      truncableText='...'
    >
      <PrevButton className='sm:flex items-center hidden mr-2 text-gray-200 hover:text-gray-300 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed'>
        <i className='pi pi-arrow-left mr-2' />
        Previous
      </PrevButton>
      <div className='flex items-center justify-center flex-grow'>
        <PageButton
          activeClassName='bg-[#03a9f4]  text-white'
          className='flex items-center justify-center h-10 w-10 rounded-full cursor-pointer'
          inactiveClassName='text-gray-500'
        />
      </div>
      <NextButton className='sm:flex items-center hidden mr-2 text-gray-200 hover:text-gray-300 focus:outline-none cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed'>
        Next
        <i className='pi pi-arrow-right ml-2' />
      </NextButton>
    </HeadlessPagination>
  );
}
