import React from "react";
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
} from "devextreme-react/data-grid";

export default function AcceptedDays({ data }) {
  return (
    <div className='my-5'>
      <h6 className='leading-5 font-semibold my-5'>Accepted date and time</h6>
      <DataGrid
        dataSource={data?.data?.acceptedDays || []}
        keyExpr='id'
        showBorders
        columnAutoWidth
        showColumnLines
        showRowLines
        columnHidingEnabled
        rowAlternationEnabled
        allowColumnResizing
        wordWrapEnabled
        cellHintEnabled
      >
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <Paging defaultPageSize={10} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[10, 15, 20]}
          showNavigationButtons={true}
          showInfo={true}
        />
        <Column dataField='date' dataType='date' />
        <Column dataField='startTime' />
        <Column dataField='endTime' />
        <Column dataField='numberOfHours' />
      </DataGrid>
    </div>
  );
}
