import React, { useState } from "react";
import { Button, TextBox, LoadPanel } from "devextreme-react";
import Form, { SimpleItem, GroupItem } from "devextreme-react/form";
import AccountService from "../../service/accountsService";
import { showNotification } from "../../utils/notification";
import { colCountByScreen } from "../../constants";
import { useAuth } from "../../contexts/auth";

export default function CompanyAccountContent() {
  const [companyCode, setCompanyCode] = useState("");
  const [accountDetails, setAccountDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { token } = useAuth();

  const getCompanyDetails = async (e) => {
    e.preventDefault();
    if (!companyCode) return;

    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await AccountService.getRegisteredAccount(
        companyCode,
        "company",
        token
      );

      setAccountDetails({
        ...data,
        contactDetails: data.contactDetails[0].address,
      });
      setIsLoading(false);
    } catch (error) {
      showNotification(error, "error");
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <LoadPanel visible />}
      <div className={"content-block"}>
        <form
          className={"flex items-center space-x-5"}
          onSubmit={getCompanyDetails}
        >
          <TextBox
            label='Company Code'
            className='w-5/12'
            value={companyCode}
            onValueChanged={(e) => {
              if (!e.value) {
                setAccountDetails(null);
              }

              setCompanyCode(e.value);
            }}
            valueChangeEvent='keyup'
            showClearButton
          />
          <Button
            text='Search Account'
            type='default'
            useSubmitBehavior
            disabled={!companyCode.trim()}
          />
        </form>
      </div>
      {accountDetails ? (
        <div className={"content-block dx-card responsive-paddings !mt-12"}>
          <Form formData={accountDetails} labelMode='floating' readOnly>
            <GroupItem
              caption='General Details'
              colCountByScreen={colCountByScreen}
            >
              <SimpleItem dataField='companyCode' />
              <SimpleItem dataField='companyName' />
              <SimpleItem dataField='regNumber' />
              <SimpleItem dataField='status' />
              <SimpleItem dataField='email' />
              <SimpleItem dataField='phone' />
              <SimpleItem dataField='industry' />
              <SimpleItem dataField='numberOfBranches' />
              <SimpleItem dataField='size' />
            </GroupItem>
            <GroupItem
              caption='Contact Details'
              colCountByScreen={colCountByScreen}
            >
              <SimpleItem
                dataField='contactDetails.addressLine'
                label={{ text: "Address" }}
              />
              <SimpleItem
                dataField='contactDetails.city'
                label={{ text: "City" }}
              />
              <SimpleItem
                dataField='contactDetails.province'
                label={{ text: "Province" }}
              />
              <SimpleItem
                dataField='contactDetails.postalCode'
                label={{ text: "Postal Code" }}
              />
              <SimpleItem
                dataField='contactDetails.country'
                label={{ text: "Country" }}
              />
            </GroupItem>
            <GroupItem
              caption='Contact Person'
              colCountByScreen={colCountByScreen}
            >
              <SimpleItem
                dataField='contactPerson.name'
                label={{ text: "Name" }}
              />
              <SimpleItem
                dataField='contactPerson.email'
                label={{ text: "Email" }}
              />
              <SimpleItem
                dataField='contactPerson.phone'
                label={{ text: "Phone" }}
              />
            </GroupItem>
          </Form>
        </div>
      ) : null}
    </>
  );
}
