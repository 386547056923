import React from "react";
import { Payments } from "../../components";

export default function PaymentPage() {
  return (
    <>
      <h2 className={"content-block"}>Manage Payments</h2>
      <Payments />
    </>
  );
}
