import React, { useState } from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  MasterDetail,
  Pager,
  Paging,
  Selection,
  Lookup,
} from "devextreme-react/data-grid";
import Form, { SimpleItem, GroupItem } from "devextreme-react/form";
import JobDays from "./JobDays";
import Attendance from "./Attendance";
import { Button, Popup, ScrollView } from "devextreme-react";
import { alert, confirm } from "devextreme/ui/dialog";
import JobService from "../../service/jobService";
import axios from "axios";
import { showNotification } from "../../utils/notification";
import "devextreme/integration/jquery";
import AccountService from "../../service/accountsService";
import { colCountByScreen } from "../../constants";
import {
  accountTypeData,
  paymentTypeData,
  payOutTypeData,
} from "../../constants/data";
import { useAuth } from "../../contexts/auth";

const AcceptedDays = ({ data }) => {
  return (
    <>
      <span className='mb-3 block font-bold'>{`${data.data.userInfo.firstName}'s Accepted Days`}</span>
      <div className='mb-9 pb-9 border-b'>
        <JobDays dataSource={data.data.acceptedDays} />
      </div>
      <span className='mb-3 block font-bold'>
        {`${data.data.userInfo.firstName}'s Attendance (Added by employer)`}
      </span>
      <div className='mb-6'>
        <Attendance dataSource={data.data.attendance} />
      </div>
    </>
  );
};

export default function AcceptedApplicants({ data, getAllJobs }) {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [profileData, setProfileData] = useState(null);

  const { token } = useAuth();

  const getAccountDetails = async (accountCode) => {
    if (!accountCode) return;

    try {
      const {
        data: { data },
      } = await AccountService.getRegisteredAccount(
        accountCode,
        "worker",
        token
      );

      setProfileData(data);
    } catch (error) {
      showNotification(error, "error");
    }
  };

  const renderTotalPayment = (e) => {
    return (
      <span>{(data.data.priceByHour * e.data.totalHours).toFixed(2)}</span>
    );
  };

  const onSelectionChanged = ({ selectedRowsData }) => {
    setSelectedUsers(selectedRowsData);
  };

  const confirmUsersPayment = async () => {
    let axiosArray = [];
    for (const request of selectedUsers) {
      axiosArray.push(
        JobService.confirmUserPayment(
          request.userInfo.userId,
          data.data.jobCode,
          token
        )
      );
    }

    try {
      await axios.all(axiosArray);

      showNotification("Successful", "success");
      getAllJobs();
    } catch (error) {
      showNotification(error, "error");
    }
  };

  const onCellPrepared = (e) => {
    if (
      (e.rowType === "data") & (e.column.command === "select") &&
      e.data.isPaymentMade
    ) {
      e.cellElement
        .find(".dx-select-checkbox")
        .dxCheckBox("instance")
        .option("disabled", true);
      e.cellElement.off();
    }
  };

  return (
    <>
      <span className='my-3 block font-bold text-lg'>Job Days</span>
      <div className='mb-9 pb-9 border-b'>
        <JobDays dataSource={data.data.taskDays} />
      </div>

      <div className='mb-9'>
        <span className='mb-3 block font-bold text-lg'>
          Accepted Applicants
        </span>
        {data.data.status === "paid" && (
          <div>
            <Button
              text='Confirm User(s) Payment'
              type='default'
              onClick={async () => {
                if (!selectedUsers.length) {
                  alert(
                    "Please select at least one user to confirm payment",
                    "Notification"
                  );

                  return;
                }

                if (selectedUsers.length > 20) {
                  alert(
                    "Please select not more than 20 users at a time to confirm payment",
                    "Notification"
                  );

                  return;
                }

                const result = await confirm(
                  "Are you sure you want to confirm payment for this user(s)?"
                );

                if (result) {
                  confirmUsersPayment();
                }
              }}
            />
          </div>
        )}
        <div className='mt-2'>
          <DataGrid
            dataSource={data.data.acceptedApplicants}
            keyExpr='id'
            showBorders
            columnAutoWidth
            showColumnLines
            showRowLines
            columnHidingEnabled
            rowAlternationEnabled
            allowColumnResizing
            wordWrapEnabled
            cellHintEnabled
            focusedRowEnabled
            onSelectionChanged={onSelectionChanged}
            onCellPrepared={data.data.status === "paid" ? onCellPrepared : {}}
          >
            {data.data.status === "paid" && (
              <Selection
                mode='multiple'
                selectAllMode={"allPages"}
                showCheckBoxesMode={"always"}
                allowSelectAll={false}
              />
            )}
            <Paging defaultPageSize={5} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[5, 10, 15]}
              showNavigationButtons={true}
              showInfo={true}
            />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Column dataField='userInfo.accountCode' caption='Account code' />
            <Column dataField='userInfo.firstName' caption='First name' />
            <Column dataField='userInfo.lastName' caption='Last name' />
            <Column
              dataField='userInfo.email'
              caption='Email'
              visible={false}
            />
            <Column
              dataField='userInfo.phone'
              caption='phone'
              visible={false}
            />

            {(data.data.status === "completed" ||
              data.data.status === "paid") && (
              <Column
                dataField='totalHours'
                caption='Total worked hours (Comfirmed by employer)'
              />
            )}
            {(data.data.status === "completed" ||
              data.data.status === "paid") && (
              <Column
                caption='Total payable amount'
                cellRender={renderTotalPayment}
                alignment='right'
              />
            )}
            {data.data.status === "paid" && (
              <Column dataField='isPaymentMade'>
                <Lookup
                  dataSource={[
                    { value: true, display: "Yes" },
                    { value: false, display: "No" },
                  ]}
                  valueExpr='value'
                  displayExpr='display'
                />
              </Column>
            )}
            {data.data.status === "paid" && (
              <Column
                caption='Payment option'
                alignment='center'
                cellRender={({ data }) => (
                  <Button
                    icon='pi pi-eye'
                    type='default'
                    onClick={() => getAccountDetails(data.userInfo.accountCode)}
                  />
                )}
              />
            )}
            <MasterDetail enabled component={AcceptedDays} />
          </DataGrid>
        </div>
      </div>

      <Popup
        visible={!!profileData}
        showCloseButton
        title='Payment option'
        onHiding={() => setProfileData(null)}
        animation={null}
      >
        <ScrollView>
          <div>
            <Form formData={profileData?.profile} labelMode='floating' readOnly>
              <GroupItem
                caption='Payment Details'
                colCountByScreen={colCountByScreen}
              >
                <SimpleItem
                  dataField='payOutType'
                  editorType='dxSelectBox'
                  editorOptions={{
                    dataSource: payOutTypeData,
                    displayExpr: "display",
                    valueExpr: "value",
                    searchEnabled: true,
                    showClearButton: false,
                  }}
                />
                <SimpleItem
                  dataField='paymentType'
                  editorType='dxSelectBox'
                  editorOptions={{
                    dataSource: paymentTypeData,
                    displayExpr: "display",
                    valueExpr: "value",
                    searchEnabled: true,
                    showClearButton: false,
                  }}
                />
                <SimpleItem dataField='eTransferEmail' />
              </GroupItem>
              <GroupItem
                caption='Bank Details'
                colCountByScreen={colCountByScreen}
              >
                <SimpleItem
                  dataField='bankDetails.accountNumber'
                  editorType='dxNumberBox'
                  label={{ text: "Account number" }}
                />

                <SimpleItem
                  dataField='bankDetails.accountType'
                  editorType='dxSelectBox'
                  label={{ text: "Account type" }}
                  editorOptions={{
                    dataSource: accountTypeData,
                    displayExpr: "display",
                    valueExpr: "value",
                    searchEnabled: false,
                    showClearButton: false,
                  }}
                />
                <SimpleItem
                  dataField='bankDetails.institutionNumber'
                  label={{ text: "Institution number" }}
                />
                <SimpleItem
                  dataField='bankDetails.transitNumber'
                  label={{ text: "Transit number" }}
                />
              </GroupItem>
            </Form>
          </div>
        </ScrollView>
      </Popup>
    </>
  );
}

// isPaymentMade
