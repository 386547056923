import React, { useState } from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
} from "devextreme-react/data-grid";
import { Button } from "devextreme-react";
import FileViewer from "react-file-viewer";
import { getExtension } from "../../../utils/general";

export default function UserDocs({ data }) {
  const [url, setUrl] = useState("");

  const renderDocButton = ({ data }) => {
    return (
      <Button
        type='default'
        icon='pi pi-eye'
        text='View Doc'
        onClick={() => {
          if (url) {
            setUrl("");
          } else {
            setUrl(data.URL);
          }
        }}
      />
    );
  };

  const onError = (e) => {
    console.log(e, "error in file-viewer");
  };

  return (
    <>
      <span className='mb-3 block font-bold text-lg'>
        {data.data.firstName}'s Documents
      </span>
      <div className='mb-9'>
        <DataGrid
          dataSource={data.data.idDocURLS}
          showBorders
          columnAutoWidth
          showColumnLines
          showRowLines
          columnHidingEnabled
          rowAlternationEnabled
          allowColumnResizing
          wordWrapEnabled
          cellHintEnabled
          keyExpr='id'
        >
          <Paging defaultPageSize={5} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[5, 10, 15]}
            showNavigationButtons={true}
            showInfo={true}
          />
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <Column dataField='fileName' />
          <Column dataField='type' />
          <Column cellRender={renderDocButton} alignment='center' />
        </DataGrid>
      </div>

      {url && (
        <div className='p-4 w-full'>
          <div className='flex justify-end mb-4'>
            <Button icon='close' onClick={() => setUrl("")} />
          </div>
          <FileViewer
            fileType={getExtension(url)}
            filePath={url}
            onError={onError}
          />
        </div>
      )}
    </>
  );
}
