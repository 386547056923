import React, { useState } from "react";
import { SelectBox } from "devextreme-react";
import Alert from "../../common/Alert";
import { jobCategories } from "../../../constants/data";
import { getUserJobDataByCategory } from "../../../utils/general";
import JobTable from "./JobTable";

export default function UserJobs({ jobs, isProfile }) {
  const [selectedCategory, setSelectedCategory] = useState("");

  const dataSource = getUserJobDataByCategory(selectedCategory, jobs || []);

  return (
    <div className={"!mt-8"}>
      {!isProfile ? (
        <Alert
          title='Profile Information'
          message={`No profile added yet by user. Account currently don't have any job`}
        />
      ) : (
        <>
          <SelectBox
            label='Select job category'
            labelMode='floating'
            className='lg:w-5/12 md:w-2/3 w-full'
            dataSource={jobCategories}
            onValueChanged={(e) => {
              setSelectedCategory(e.value);
            }}
            displayExpr='display'
            valueExpr='value'
            searchEnabled
            showClearButton
          />
          {selectedCategory && dataSource ? (
            <div className={"dx-card responsive-paddings !mt-8"}>
              <JobTable
                dataSource={dataSource}
                isAcceptedJob={selectedCategory === "acceptedJobs"}
                isAppliedJob={selectedCategory === "appliedJobs"}
                isCompletedJob={selectedCategory === "completedJobs"}
                isInProgressJob={selectedCategory === "inProgressJobs"}
                isPaid={selectedCategory === "paidJobs"}
              />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}
