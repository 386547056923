import React from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Lookup,
  MasterDetail,
} from "devextreme-react/data-grid";
import Pagination from "../common/Pagination";
import { jobStatusArray } from "../../constants/data";
import Applicants from "./Applicants";
import AcceptedApplicants from "./AcceptedApplicants";
import { Button } from "devextreme-react";
import JobService from "../../service/jobService";
import { showNotification } from "../../utils/notification";
import { confirm } from "devextreme/ui/dialog";
import { useAuth } from "../../contexts/auth";

export default function JobDataTable({
  currentPage,
  dataSource,
  status,
  totalPages,
  setCurrentPage,
  confirmPayment,
  getAllJobs,
  approveJob,
}) {
  const { token } = useAuth();

  const downloadInvoice = async (id) => {
    try {
      const { data } = await JobService.downloadInvoice(id, token);

      window.open(URL.createObjectURL(data));
    } catch (error) {
      showNotification(error, "error");
    }
  };

  const renderInvoiceButton = ({ data }) => {
    // isPaymentConfirmed
    return (
      <div className='flex items-center space-x-2 justify-center'>
        <div>
          <Button
            type='default'
            stylingMode='outlined'
            text='Invoice'
            hint='View invoice'
            onClick={() => downloadInvoice(data._id)}
          />
        </div>
        {status === "paid" && (
          <div>
            {data.isPaymentConfirmed ? (
              <span>Payment confirmed</span>
            ) : (
              <Button
                type='default'
                stylingMode='outlined'
                text='Confirm payment'
                hint='Confirm payment'
                onClick={async () => {
                  const result = await confirm(
                    "Are you sure you want to confirm payment for this job ?",
                    "Confirm"
                  );

                  if (result) {
                    confirmPayment({
                      jobId: data._id,
                      invoiceAmount: data.invoiceAmount,
                    });
                  }
                }}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  const renderApproveJobButton = ({ data }) => {
    return (
      <Button
        text='Approve Job'
        type='default'
        hint='Approve Job'
        className='w-full'
        onClick={async () => {
          const result = await confirm(
            "Are you sure you want to approve this job ?",
            "Confirm"
          );

          if (result) {
            approveJob(data._id);
          }
        }}
      />
    );
  };

  return (
    <>
      <DataGrid
        dataSource={dataSource}
        showBorders
        columnAutoWidth
        showColumnLines
        showRowLines
        columnHidingEnabled
        rowAlternationEnabled
        allowColumnResizing
        wordWrapEnabled
        cellHintEnabled
        focusedRowEnabled
        keyExpr='id'
      >
        <FilterRow visible={true} />
        <SearchPanel visible={true} />
        <HeaderFilter visible={true} />
        <ColumnChooser enabled={true} mode='select' allowSearch={true} />
        <Column dataField='jobCode' />
        <Column dataField='title' />
        <Column
          dataField='startDate'
          visible={status === "accepting-application" ? true : false}
        />
        <Column
          dataField='endDate'
          visible={status === "accepting-application" ? true : false}
        />
        <Column dataField='jobPosterName' />
        {/* <Column dataField='numberOfDays' /> */}
        <Column dataField='priceByHour' />
        {(status === "completed" || status === "paid") && (
          <Column dataField='totalWorkHours' />
        )}
        {(status === "completed" || status === "paid") && (
          <Column dataField='totalWorkAmount' visible={false} />
        )}
        {(status === "completed" || status === "paid") && (
          <Column dataField='invoiceAmount' />
        )}

        <Column dataField='status' visible={false}>
          <Lookup
            dataSource={jobStatusArray.filter(
              (st) => st.value !== "Yes" && st.value !== "No"
            )}
            displayExpr='display'
            valueExpr='value'
          />
        </Column>

        {(status === "completed" || status === "paid") && (
          <Column
            cellRender={renderInvoiceButton}
            alignment='center'
            width={status === "paid" ? 300 : 150}
          />
        )}

        {status === "No" && (
          <Column
            cellRender={renderApproveJobButton}
            alignment='center'
            width={300}
          />
        )}

        {status === "accepting-application" && (
          <MasterDetail enabled component={Applicants} />
        )}
        {(status === "in-progress" ||
          status === "completed" ||
          status === "paid") && (
          <MasterDetail
            enabled
            component={(props) => (
              <AcceptedApplicants {...props} getAllJobs={getAllJobs} />
            )}
          />
        )}

        {/* {(status === "completed" || status === "paid") && (
          <Column cellRender={renderInvoiceButton} alignment='center' />
        )} */}
      </DataGrid>
      <div className='mt-9'>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      </div>
    </>
  );
}
