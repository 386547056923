import React from "react";
import { useAuth } from "../../contexts/auth";
import "./home.scss";

export default function Home() {
  const { totals } = useAuth();

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Dashboard</h2>
      <div className={"content-block"}>
        <div
          className={
            "dx-card responsive-paddings flex flex-wrap space-x-3 justify-center"
          }
        >
          {totals ? (
            <div className='container mx-auto px-20'>
              <div className='flex flex-col w-full'>
                <div className='grid gap-4 grid-cols-1 sm:grid-cols-2 my-2 w-full'>
                  <div className='metric-card bg-white dark:bg-gray-900 border border-gray-200 dark:border-gray-800 rounded-lg p-4 max-w-72 w-full'>
                    <div className='flex items-center text-gray-900 dark:text-gray-100'>
                      Number of accounts
                    </div>
                    <p className='mt-2 text-3xl font-bold spacing-sm text-black dark:text-white'>
                      {totals.accounts}
                    </p>
                  </div>
                  <div className='metric-card bg-white dark:bg-gray-900 border border-gray-200 dark:border-gray-800 rounded-lg p-4 max-w-72 w-full'>
                    <div className='flex items-center text-gray-900 dark:text-gray-100'>
                      Number of companies
                    </div>
                    <p className='mt-2 text-3xl font-bold spacing-sm text-black dark:text-white'>
                      {totals.companies}
                    </p>
                  </div>
                  <div className='metric-card bg-white dark:bg-gray-900 border border-gray-200 dark:border-gray-800 rounded-lg p-4 max-w-72 w-full'>
                    <div className='flex items-center text-gray-900 dark:text-gray-100'>
                      Number of private person's profile
                    </div>
                    <p className='mt-2 text-3xl font-bold spacing-sm text-black dark:text-white'>
                      {totals.privatePersonProfiles}
                    </p>
                  </div>
                  <div className='metric-card bg-white dark:bg-gray-900 border border-gray-200 dark:border-gray-800 rounded-lg p-4 max-w-72 w-full'>
                    <div className='flex items-center text-gray-900 dark:text-gray-100'>
                      Number of active private person's profile
                    </div>
                    <p className='mt-2 text-3xl font-bold spacing-sm text-black dark:text-white'>
                      {totals.activePrivatePersonProfiles}
                    </p>
                  </div>
                </div>
                <div className='grid gap-4 grid-cols-1 sm:grid-cols-2 my-2 w-full'>
                  <div className='metric-card bg-white dark:bg-gray-900 border border-gray-200 dark:border-gray-800 rounded-lg p-4 max-w-72 w-full'>
                    <div className='flex items-center text-gray-900 dark:text-gray-100'>
                      Total user's (workers) profile
                    </div>
                    <p className='mt-2 text-3xl font-bold spacing-sm text-black dark:text-white'>
                      {totals.workersProfiles}
                    </p>
                  </div>
                  <div className='metric-card bg-white dark:bg-gray-900 border border-gray-200 dark:border-gray-800 rounded-lg p-4 max-w-72 w-full'>
                    <div className='flex items-center text-gray-900 dark:text-gray-100'>
                      Total active user's (workers) profile
                    </div>
                    <p className='mt-2 text-3xl font-bold spacing-sm text-black dark:text-white'>
                      {totals.activeProfile}
                    </p>
                  </div>
                  <div className='metric-card bg-white dark:bg-gray-900 border border-gray-200 dark:border-gray-800 rounded-lg p-4 max-w-72 w-full'>
                    <div className='flex items-center text-gray-900 dark:text-gray-100'>
                      Number of jobs
                    </div>
                    <p className='mt-2 text-3xl font-bold spacing-sm text-black dark:text-white'>
                      {totals.jobs}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
}
