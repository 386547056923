import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import AccountService from "../service/accountsService";
import AuthService from "../service/authService";
import {
  removeUserFromLocalStaorage,
  setUserToLocalStorage,
} from "../utils/general";
import { showNotification } from "../utils/notification";

function AuthProvider(props) {
  const [user, setUser] = useState(null);
  const [totals, setTotals] = useState(null);
  const [token, setToken] = useState(null);

  const getUser = async (isErrorNeeded = false) => {
    try {
      const {
        data: { data },
      } = await AuthService.getUser();
      setUser(data);
      getTotals();
    } catch (error) {
      if (isErrorNeeded) {
        showNotification(error, "error");
      }
    }
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  const signIn = useCallback(async (loginDetails) => {
    try {
      const {
        data: { token, data },
      } = await AuthService.loginUser(loginDetails);
      setToken(token);
      getTotals(token);
      setUserToLocalStorage(token);
      setUser(data);
      return {
        success: true,
      };
    } catch (error) {
      showNotification(error, "error");
      return {
        success: false,
      };
    }

    // eslint-disable-next-line
  }, []);

  const signOut = useCallback(async () => {
    try {
      await AuthService.logout(token);
      removeUserFromLocalStaorage();
      setUser(null);
      setToken(null);
    } catch (error) {
      showNotification(error, "error");
    }

    // eslint-disable-next-line
  }, []);

  const getTotals = async (token) => {
    try {
      const {
        data: { data },
      } = await AccountService.getTotals(token);

      setTotals(data);
    } catch (error) {}
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        signIn,
        signOut,
        getUser,
        totals,
        token,
      }}
      {...props}
    />
  );
}

const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
