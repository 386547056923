import React from "react";
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Lookup,
  MasterDetail,
} from "devextreme-react/data-grid";
import Badge from "../../common/Badge";
import { appliedJobStatus } from "../../../constants/data";
import AcceptedDays from "./AcceptedDays";
import Attendance from "../../jobs/Attendance";

const trueData = [
  {
    value: true,
    display: "Yes",
  },
  {
    value: false,
    display: "No",
  },
];

const AttendanceTable = ({ data }) => {
  return (
    <>
      <span className='mb-3 block font-bold text-lg'>
        Attendance (Added by user)
      </span>
      <Attendance dataSource={data.data.attendance} />
    </>
  );
};

export default function JobTable({
  dataSource,
  isAcceptedJob,
  isAppliedJob,
  isCompletedJob,
  isInProgressJob,
  isPaid,
}) {
  const renderStatus = ({ data }) => {
    switch (data.status) {
      case "in-review":
        return <Badge status='in-review'>In Review</Badge>;
      case "accepted":
        return <Badge status='accepted'>Accepted</Badge>;
      case "rejected":
        return <Badge status='rejected'>Rejected</Badge>;
      default:
        return <span>{data.status}</span>;
    }
  };

  return (
    <DataGrid
      dataSource={dataSource}
      keyExpr='jobCode'
      showBorders
      columnAutoWidth
      showColumnLines
      showRowLines
      columnHidingEnabled
      rowAlternationEnabled
      allowColumnResizing
      wordWrapEnabled
      cellHintEnabled
      focusedRowEnabled={isAcceptedJob || isInProgressJob}
    >
      <FilterRow visible={true} />
      <SearchPanel visible={true} />
      <HeaderFilter visible={true} />
      <Paging defaultPageSize={10} />
      <Pager
        showPageSizeSelector={true}
        allowedPageSizes={[10, 15, 20]}
        showNavigationButtons={true}
        showInfo={true}
      />
      <ColumnChooser enabled={true} mode='select' allowSearch={true} />
      <Column dataField='jobCode' caption='Task Code' />
      <Column dataField='title' caption='Task Title' />
      {/* <Column dataField='startDate' dataType='date' />
      <Column dataField='endDate' dataType='date' /> */}
      <Column dataField='jobPosterName' caption='Job Poster' />
      <Column dataField='priceByHour' />
      {isCompletedJob && <Column dataField='totalHours' />}
      {isCompletedJob && <Column dataField='totalPayableAmount' />}
      {isPaid && <Column dataField='amountPaid' />}
      {isAcceptedJob && (
        <Column dataField='isJobStarted'>
          <Lookup
            dataSource={trueData}
            valueExpr='value'
            displayExpr='display'
          />
        </Column>
      )}
      <Column caption='Location'>
        <Column dataField='location.addressLine' caption='Address' />
        <Column dataField='location.city' caption='City' visible={false} />
        <Column
          dataField='location.country'
          caption='Country'
          visible={false}
        />
        <Column
          dataField='location.postalCode'
          caption='Postal Code'
          visible={false}
        />
        <Column
          dataField='location.province'
          caption='Province'
          visible={false}
        />
      </Column>
      {isAppliedJob && (
        <Column
          dataField='status'
          caption='Application Status'
          cellRender={renderStatus}
          alignment='center'
          allowHeaderFiltering={true}
          allowFiltering={false}
        >
          <Lookup
            dataSource={appliedJobStatus}
            valueExpr='value'
            displayExpr='display'
          />
        </Column>
      )}
      {isAcceptedJob && <MasterDetail enabled component={AcceptedDays} />}
      {(isCompletedJob || isInProgressJob) && (
        <MasterDetail enabled component={AttendanceTable} />
      )}
    </DataGrid>
  );
}
