import API from "../api/apiConfig";

export const authServiceQueryKeys = {
  GET_USER: "GET_USER",
};

const AuthService = {
  loginUser: async (loginDetails) => {
    return await API.post("/auth/login", {
      ...loginDetails,
      accountType: "taskpip",
    });
  },

  createAccount: async (accDetails) => {
    return await API.post("/auth/register", accDetails);
  },

  getUser: async (token) => {
    if (token) {
      return await API.get("/auth/me", {
        headers: { token },
        params: { accountType: "taskpip" },
      });
    }
    return await API.get("/auth/me", {
      params: { accountType: "taskpip" },
    });
  },

  logout: async (token) => {
    if (token) {
      return await API.post("/auth/logout", null, {
        headers: { token },
      });
    }

    return await API.post("/auth/logout");
  },
};

export default AuthService;
