import {
  HomePage,
  JobsPage,
  UserAccountPage,
  CompanyAccountPage,
  ManageUserProfilePage,
  PaymentPage,
  ManagePrivatePersonProfilePage,
} from "./pages";
import { withNavigationWatcher } from "./contexts/navigation";

const routes = [
  {
    path: "/manage-jobs",
    element: JobsPage,
  },
  {
    path: "/manage-payments",
    element: PaymentPage,
  },
  {
    path: "/user-account",
    element: UserAccountPage,
  },
  {
    path: "/company-account",
    element: CompanyAccountPage,
  },
  {
    path: "/manage-user-profile",
    element: ManageUserProfilePage,
  },
  {
    path: "/private-person",
    element: ManagePrivatePersonProfilePage,
  },
  // {
  //   path: "/profile",
  //   element: ProfilePage,
  // },
  {
    path: "/dashboard",
    element: HomePage,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});
