import API from "../api/apiConfig";

export const jobServiceQueryKeys = {
  GET_ALL_JOBS: "GET_ALL_JOBS",
  GET_JOB_STATUS: "GET_JOB_STATUS",
  GET_JOB_DETAILS: "GET_JOB_DETAILS",
};

const JobService = {
  createJob: async (jobData) => {
    return await API.post("/job", jobData);
  },

  getAllJobs: async (status) => {
    if (status) {
      const { data } = await API.get("/job/user/me", {
        params: { status },
      });

      return data;
    } else {
      const { data } = await API.get("/job/user/me");

      return data;
    }
  },

  getSingleJob: async (id) => {
    const { data } = await API.get(`/job/${id}`);
    return data;
  },

  updateJob: async (jobData, id) => {
    return await API.put(`/job/${id}`, jobData);
  },

  decideApplication: async (jobId, requestData, token) => {
    if (token) {
      return await API.post(`/job/${jobId}/decide`, requestData, {
        headers: { token },
      });
    }

    return await API.post(`/job/${jobId}/decide`, requestData);
  },

  startJob: async (id) => {
    return await API.post(`/job/${id}/start`);
  },

  addAttendance: async (jobId, requestData) => {
    return await API.post(`/job/${jobId}/attendance`, requestData);
  },

  completeJob: async (jobId) => {
    return await API.post(`/job/${jobId}/complete`);
  },

  downloadInvoice: async (jobId, token) => {
    if (token) {
      return await API.get(`/job/${jobId}/invoice/download`, {
        responseType: "blob",
        headers: { token },
      });
    }

    return await API.get(`/job/${jobId}/invoice/download`, {
      responseType: "blob",
    });
  },

  confirmPayment: async (jobId, paymentMade, token) => {
    if (token) {
      return await API.post(
        `/admin/job/${jobId}/confirmpayment`,
        {
          paymentMade,
        },
        {
          headers: { token },
        }
      );
    }

    return await API.post(`/admin/job/${jobId}/confirmpayment`, {
      paymentMade,
    });
  },

  confirmUserPayment: async (userId, jobCode, token) => {
    if (token) {
      return await API.post(
        `/admin/user/${userId}/confirmpayment`,
        {
          jobCode,
        },
        { headers: { token } }
      );
    }

    return await API.post(`/admin/user/${userId}/confirmpayment`, {
      jobCode,
    });
  },

  approveJob: async (jobId, token) => {
    if (token) {
      return await API.put(
        `/admin/job/${jobId}/approve`,
        { isTaskApproved: true },
        {
          headers: { token },
        }
      );
    }

    return await API.put(
      `/admin/job/${jobId}/approve`,
      { isTaskApproved: true },
      {
        headers: { token },
      }
    );
  },

  // attendance
};

export default JobService;
