import React from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  MasterDetail,
  Pager,
  Paging,
} from "devextreme-react/data-grid";
import JobDays from "./JobDays";

const AppliedDays = ({ data }) => {
  return (
    <>
      <span className='mb-3 block font-bold'>
        {`${data.data.userInfo.firstName}'s Applied Days`}
      </span>
      <JobDays dataSource={data.data.appliedDays} />
    </>
  );
};

export default function Applicants({ data }) {
  return (
    <>
      <span className='my-3 block font-bold text-lg'>Job Days</span>
      <div className='mb-9 pb-9 border-b'>
        <JobDays dataSource={data.data.taskDays} />
      </div>
      <span className='mb-3 block font-bold text-lg'>Applicants</span>
      <div className='mb-9'>
        <DataGrid
          dataSource={data.data.applicants}
          keyExpr='id'
          showBorders
          columnAutoWidth
          showColumnLines
          showRowLines
          columnHidingEnabled
          rowAlternationEnabled
          allowColumnResizing
          wordWrapEnabled
          cellHintEnabled
          focusedRowEnabled
        >
          <Paging defaultPageSize={5} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[5, 10, 15]}
            showNavigationButtons={true}
            showInfo={true}
          />
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <Column dataField='userInfo.firstName' caption='First name' />
          <Column dataField='userInfo.lastName' caption='Last name' />
          <Column dataField='userInfo.email' caption='Email' />
          <Column dataField='userInfo.phone' caption='phone' />
          <MasterDetail enabled component={AppliedDays} />
        </DataGrid>
      </div>
    </>
  );
}
