import React from "react";

export default function Badge({ status, children }) {
  // case "in-review":
  //     return <Badge tw='bg-yellow-700 text-yellow-200'>In Review</Badge>;
  //   case "accepted":
  //     return <Badge tw='bg-primary-500 text-white'>Accepted</Badge>;
  //   case "rejected":
  //     return <Badge tw='bg-red-600 text-red-200'>Rejected</Badge>;

  const getTypeClass = () => {
    switch (status) {
      case "in-review":
        return "bg-yellow-700 text-yellow-200";

      case "accepted":
        return "bg-sky-500 text-white";

      case "rejected":
        return "bg-red-600 text-red-200";
      default:
        return "bg-sky-500 text-white";
    }
  };

  return (
    <span
      className={`inline-flex items-center justify-center px-2 py-1 text-xs font-bold rounded leading-none ${getTypeClass()}`}
    >
      {children}
    </span>
  );
}
