import {
  employmentStatusData,
  relationshipData,
  residenceStatusData,
} from "../constants/data";

export const setUserToLocalStorage = (token) => {
  localStorage.setItem(`tp-token-p`, token);
};
export const removeUserFromLocalStaorage = () => {
  localStorage.removeItem(`tp-token-p`);
};

export const getToken = () => localStorage.getItem(`tp-token-p`);

export const filterRequestData = (keys, requestBody) => {
  const result = {};

  const body = JSON.parse(JSON.stringify(requestBody));

  for (let key in body) {
    if (keys.includes(key)) {
      result[key] = body[key];
    }
  }

  return result;
};

export const getResidenceStatus = (residence) => {
  const res = residenceStatusData.find((r) => r.value === residence);
  if (res || !residence) {
    return residenceStatusData;
  }

  return [...residenceStatusData, { value: residence, display: residence }];
};

export const getEmploymentStatusData = (status) => {
  const res = employmentStatusData.find((s) => s.value === status);
  if (res || !status) {
    return employmentStatusData;
  }

  return [...employmentStatusData, { value: status, display: status }];
};

export const getRelationshipStatus = (status) => {
  const res = relationshipData.find((s) => s.value === status);
  if (res || !status) {
    return relationshipData;
  }

  return [...relationshipData, { value: status, display: status }];
};

export const getUserJobDataByCategory = (category, jobs) => {
  return jobs[category] || null;
};

// Gets file extension from URL, or return false if there's no extension
export const getExtension = (url) => {
  // Extension starts after the first dot after the last slash
  let extStart = url.indexOf(".", url.lastIndexOf("/") + 1);
  if (extStart === -1) return false;
  let ext = url.substr(extStart + 1),
    // end of extension must be one of: end-of-string or question-mark or hash-mark
    extEnd = ext.search(/$|[?#]/);
  return ext.substring(0, extEnd);
};
