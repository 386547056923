import React from "react";
import { ManagePrivatePersonProfile } from "../../components";

export default function ManagePrivatePersonProfilePage() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Manage Private Person Profile</h2>
      <ManagePrivatePersonProfile />
    </React.Fragment>
  );
}
