export const jobStatusArray = [
  { display: "Un-Published", value: "new" },
  { display: "Accepting Application", value: "accepting-application" },
  { display: "Not Accepting Application", value: "not-accepting-application" },
  { display: "In Progress", value: "in-progress" },
  { display: "Completed", value: "completed" },
  { display: "Paid", value: "paid" },
  { display: "Task Approved", value: "Yes" },
  { display: "Task Not Approved", value: "No" },
];

export const testJobData = [
  {
    jobCode: 1,
    title: "This is test title",
    startDate: "2/25/2022",
    endDate: "3/10/2022",
    numberOfDays: 5,
    priceByHour: 25,
    status: "new",
    totalWorkHours: 300,
    totalWorkers: 10,
  },
  {
    jobCode: 2,
    title: "This is test title Inprogress",
    startDate: "2/25/2022",
    endDate: "3/10/2022",
    numberOfDays: 5,
    priceByHour: 25,
    status: "in-progress",
    totalWorkHours: 300,
    totalWorkers: 10,
  },
  {
    jobCode: 3,
    title: "This is test title Completed",
    startDate: "2/25/2022",
    endDate: "3/10/2022",
    numberOfDays: 5,
    priceByHour: 25,
    status: "completed",
    totalWorkHours: 300,
    totalWorkers: 10,
  },
  {
    jobCode: 4,
    title: "This is test title Paid",
    startDate: "2/25/2022",
    endDate: "3/10/2022",
    numberOfDays: 5,
    priceByHour: 25,
    status: "paid",
    totalWorkHours: 300,
    totalWorkers: 10,
  },
  {
    jobCode: 5,
    title: "This is test title Published",
    startDate: "2/25/2022",
    endDate: "3/10/2022",
    numberOfDays: 5,
    priceByHour: 25,
    status: "accepting-application",
    totalWorkHours: 300,
    totalWorkers: 10,
  },
];

export const provinceMap = {
  NL: "Newfoundland and Labrador",
  PE: "Prince Edward Island",
  NS: "Nova Scotia",
  NB: "New Brunswick",
  QC: "Quebec",
  ON: "Ontario",
  MB: "Manitoba",
  SK: "Saskatchewan",
  AB: "Alberta",
  BC: "British Columbia",
  YT: "Yukon",
  NT: "Northwest Territories",
  NU: "Nunavut",
};

export const industriesList = [
  "Accounting",
  "Airlines / Aviation",
  "Alternative Dispute Resolution",
  "Alternative Medicine",
  "Animation",
  "Apparel / Fashion",
  "Architecture / Planning",
  "Arts / Crafts",
  "Automotive",
  "Aviation / Aerospace",
  "Banking / Mortgage",
  "Biotechnology / Greentech",
  "Broadcast Media",
  "Building Materials",
  "Business Supplies / Equipment",
  "Capital Markets / Hedge Fund / Private Equity",
  "Chemicals",
  "Civic / Social Organization",
  "Civil Engineering",
  "Commercial Real Estate",
  "Computer Games",
  "Computer Hardware",
  "Computer Networking",
  "Computer Software / Engineering",
  "Computer / Network Security",
  "Construction",
  "Consumer Electronics",
  "Consumer Goods",
  "Consumer Services",
  "Cosmetics",
  "Dairy",
  "Defense / Space",
  "Design",
  "E - Learning",
  "Education Management",
  "Electrical / Electronic Manufacturing",
  "Entertainment / Movie Production",
  "Environmental Services",
  "Events Services",
  "Executive Office",
  "Facilities Services",
  "Farming",
  "Financial Services",
  "Fine Art",
  "Fishery",
  "Food Production",
  "Food / Beverages",
  "Fundraising",
  "Furniture",
  "Gambling / Casinos",
  "Glass / Ceramics / Concrete",
  "Government Administration",
  "Government Relations",
  "Graphic Design / Web Design",
  "Health / Fitness",
  "Higher Education / Acadamia",
  "Hospital / Health Care",
  "Hospitality",
  "Human Resources / HR",
  "Import / Export",
  "Individual / Family Services",
  "Industrial Automation",
  "Information Services",
  "Information Technology / IT",
  "Insurance",
  "International Affairs",
  "International Trade / Development",
  "Internet",
  "Investment Banking / Venture",
  "Investment Management / Hedge Fund / Private Equity",
  "Judiciary",
  "Law Enforcement",
  "Law Practice / Law Firms",
  "Legal Services",
  "Legislative Office",
  "Leisure / Travel",
  "Library",
  "Logistics / Procurement",
  "Luxury Goods / Jewelry",
  "Machinery",
  "Management Consulting",
  "Maritime",
  "Market Research",
  "Marketing / Advertising / Sales",
  "Mechanical or Industrial Engineering",
  "Media Production",
  "Medical Equipment",
  "Medical Practice",
  "Mental Health Care",
  "Military Industry",
  "Mining / Metals",
  "Motion Pictures / Film",
  "Museums / Institutions",
  "Music",
  "Nanotechnology",
  "Newspapers / Journalism",
  "Non - Profit / Volunteering",
  "Oil / Energy / Solar / Greentech",
  "Online Publishing",
  "Other Industry",
  "Outsourcing / Offshoring",
  "Package / Freight Delivery",
  "Packaging / Containers",
  "Paper / Forest Products",
  "Performing Arts",
  "Pharmaceuticals",
  "Philanthropy",
  "Photography",
  "Plastics",
  "Political Organization",
  "Primary / Secondary Education",
  "Printing",
  "Professional Training",
  "Program Development",
  "Public Relations / PR",
  "Public Safety",
  "Publishing Industry",
  "Railroad Manufacture",
  "Ranching",
  "Real Estate / Mortgage",
  "Recreational Facilities / Services",
  "Religious Institutions",
  "Renewables / Environment",
  "Research Industry",
  "Restaurants",
  "Retail Industry",
  "Security / Investigations",
  "Semiconductors",
  "Shipbuilding",
  "Sporting Goods",
  "Sports",
  "Staffing / Recruiting",
  "Supermarkets",
  "Telecommunications",
  "Textiles",
  "Think Tanks",
  "Tobacco",
  "Translation / Localization",
  "Transportation",
  "Utilities",
  "Venture Capital / VC",
  "Veterinary",
  "Warehousing",
  "Wholesale",
  "Wine / Spirits",
  "Wireless",
  "Writing / Editing",
];

export const testResponse = [
  {
    corporationId: "13612732",
    act: "Canada Business Corporations Act",
    status: "Active",
    corporationNames: [
      {
        CorporationName: {
          name: "taskpip Inc.",
          nameType: "Primary",
          current: true,
          effectiveDate: "2021-12-20",
        },
      },
    ],
    adresses: [
      {
        address: {
          addressLine: ["200 Bay St S", "1112"],
          city: "Hamilton",
          postalCode: "L8P 4S4",
          provinceCode: "ON",
          countryCode: "CA",
        },
      },
    ],
    directorLimits: {
      minimum: 1,
      maximum: 25,
    },
    businessNumbers: {
      businessNumber: "758976104",
    },
    annualReturns: [],
    activities: [
      {
        activity: {
          activity: "Incorporation",
          date: "2021-12-20",
        },
      },
    ],
  },
  {
    corporationId: "13612732",
    act: "Loi canadienne sur les sociétés par actions",
    status: "Actif",
    corporationNames: [
      {
        CorporationName: {
          name: "taskpip Inc.",
          nameType: "principal",
          current: true,
          effectiveDate: "2021-12-20",
        },
      },
    ],
    adresses: [
      {
        address: {
          addressLine: ["200 Bay St S", "1112"],
          city: "Hamilton",
          postalCode: "L8P 4S4",
          provinceCode: "ON",
          countryCode: "CA",
        },
      },
    ],
    directorLimits: {
      minimum: 1,
      maximum: 25,
    },
    businessNumbers: {
      businessNumber: "758976104",
    },
    annualReturns: [],
    activities: [
      {
        activity: {
          activity: "Constitution en société",
          date: "2021-12-20",
        },
      },
    ],
  },
];

export const educationLevelData = [
  {
    value: "primary",
    display: "Primary education",
  },
  {
    value: "secondary",
    display: "Secondary education/college",
  },
  {
    value: "higher",
    display: "Higher education",
  },
  {
    value: "other",
    display: "Other",
  },
];

export const jobPrefrenceData = [
  {
    value: "casual",
    display: "Casual",
  },
  {
    value: "partTime",
    display: "Part time",
  },
  {
    value: "fullTime",
    display: "Full time",
  },
  {
    value: "openToAny",
    display: "Open to any",
  },
];

export const employmentStatusData = [
  {
    value: "unemployed",
    display: "Unemployed",
  },
  {
    value: "retired",
    display: "Retired",
  },
  {
    value: "student",
    display: "Student",
  },
  {
    value: "partTimeEmployed",
    display: "Part - time employed",
  },
  {
    value: "selfEmployed",
    display: "Self-employed/business owner",
  },
  {
    value: "other",
    display: "Other",
  },
];

// student visa, work permit, PR, Canadian citizen, others

export const residenceStatusData = [
  {
    value: "studentVisa",
    display: "Student visa",
  },
  {
    value: "workPermit",
    display: "Work permit",
  },
  {
    value: "pr",
    display: "Permanent resident",
  },
  {
    value: "citizen",
    display: "Canadian citizen",
  },
  {
    value: "other",
    display: "Other",
  },
];

export const payOutTypeData = [
  //   {
  //     value: "daily",
  //     display: "Daily",
  //   },
  {
    value: "weekly",
    display: "Weekly",
  },
  {
    value: "bi-weekly",
    display: "Bi weekly",
  },
  {
    value: "monthly",
    display: "Monthly",
  },
];

export const paymentTypeData = [
  {
    value: "directDeposit",
    display: "Direct deposit",
  },
  {
    value: "eTransfer",
    display: "E-transfer",
  },
  //   {
  //     value: "cash",
  //     display: "Cash (coming soon)",
  //   },
];

export const accountTypeData = [
  {
    value: "checking",
    display: "Checking",
  },
  {
    value: "savings",
    display: "Savings",
  },
];

export const relationshipData = [
  {
    value: "spouse",
    display: "Spouse",
  },
  {
    value: "mother",
    display: "Mother",
  },
  {
    value: "father",
    display: "Father",
  },
  {
    value: "brother",
    display: "Brother",
  },
  {
    value: "sister",
    display: "Sister",
  },
  {
    value: "other",
    display: "Other",
  },
];

export const documentTypeData = [
  "Drivers' licence",
  "Student visa",
  "Work permit",
  "PR card",
  "Bio data page of international",
  "Resume",
  "Other",
];

export const userProfileFields = [
  "firstName",
  "lastName",
  "address",
  "phone",
  "email",
  "jobPrefrence",
  "workPrefrence",
  "employmentStatus",
  "lang",
  "residenceStatus",
  "paymentType",
  "payOutType",
  "education",
  "sinNumber",
  "bankDetails",
  "idDocURLS",
  "resume",
  "emergencyContact",
];

export const appliedJobStatus = [
  {
    value: "in-review",
    display: "In Review",
  },
  {
    value: "accepted",
    display: "Accepted",
  },
  {
    value: "rejected",
    display: "Rejected",
  },
];

export const jobCategories = [
  {
    value: "appliedJobs",
    display: "Applied Jobs",
  },
  {
    value: "acceptedJobs",
    display: "Accepted Jobs",
  },
  {
    value: "inProgressJobs",
    display: "In-progress Jobs",
  },
  {
    value: "completedJobs",
    display: "Completed Jobs",
  },
  {
    value: "paidJobs",
    display: "Paid Jobs",
  },
];

export const statusData = [
  {
    value: "pending",
    display: "Pending Approval",
  },
  {
    value: "active",
    display: "Active",
  },
  {
    value: "de-activated",
    display: "Deactivated",
  },
];
