import API from "../api/apiConfig";

export const accountServiceQueryKeys = {
  GET_USER: "GET_REGISTERED_ACCOUNT",
};

const AccountService = {
  getRegisteredAccount: async (accountCode, accountType, token) => {
    if (token) {
      return await API.get("/admin/account", {
        params: { accountCode, accountType },
        headers: { token },
      });
    }

    return await API.get("/admin/account", {
      params: { accountCode, accountType },
    });
  },
  getUsersProfile: async (filter, token) => {
    if (token) {
      return await API.get("/admin/users/profile", {
        params: { ...filter },
        headers: { token },
      });
    }

    return await API.get("/admin/users/profile", {
      params: { ...filter },
    });
  },

  getPrivatePersonProfile: async (filter, token) => {
    if (token) {
      return await API.get("/admin/user/private/profile", {
        params: { ...filter },
        headers: { token },
      });
    }

    return await API.get("/admin/user/private/profile", {
      params: { ...filter },
    });
  },

  updateProfileStatus: async (
    { profileStatus, accountCode, accountType },
    token
  ) => {
    if (token) {
      return await API.put(
        `/admin/user/profile/${accountCode}`,
        {
          profileStatus,
          accountType,
        },
        {
          headers: { token },
        }
      );
    }

    return await API.put(`/admin/user/profile/${accountCode}`, {
      profileStatus,
      accountType,
    });
  },

  getAllJobs: async (filter, token) => {
    if (token) {
      return await API.get("/admin/jobs", {
        params: { ...filter },
        headers: { token },
      });
    }

    return await API.get("/admin/jobs", {
      params: { ...filter },
    });
  },

  getTotals: async (token) => {
    if (token) {
      return await API.get("/admin/totals", { headers: { token } });
    }

    return await API.get("/admin/totals");
  },
};

export default AccountService;
