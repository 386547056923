import React from "react";
import { ManageUserProfile } from "../../components";

export default function ManageUserProfilePage() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Manage Profile (Workers)</h2>
      <ManageUserProfile />
    </React.Fragment>
  );
}
