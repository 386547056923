import React, { useState, Suspense } from "react";
import { Button, LoadPanel, TextBox, TabPanel } from "devextreme-react";
import AccountService from "../../service/accountsService";
import { showNotification } from "../../utils/notification";
import AccountDetails from "./user/AccountDetails";

import UserJobs from "./user/UserJobs";
import { useAuth } from "../../contexts/auth";

// const AccountDetails = React.lazy(() => import("./user/AccountDetails"));
const ProfileDetails = React.lazy(() => import("./user/ProfileDetails"));
// const JobDetails = React.lazy(() => import("./user/UserJobs"));

const items = [
  {
    text: "details",
    title: "Account Details",
  },
  {
    text: "profile",
    title: "Profile Details",
  },
  {
    text: "jobs",
    title: "Jobs",
  },
];

export default function UserAccountContent() {
  const [accountCode, setAccountCode] = useState("");
  const [accountDetails, setAccountDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { token } = useAuth();

  const getAccountDetails = async (e) => {
    e.preventDefault();

    if (!accountCode) return;

    setIsLoading(true);

    try {
      const {
        data: { data },
      } = await AccountService.getRegisteredAccount(
        accountCode,
        "worker",
        token
      );

      setAccountDetails(data);
      setIsLoading(false);
    } catch (error) {
      showNotification(error, "error");
      setIsLoading(false);
    }
  };

  const onSelectionChanged = (args) => {
    if (args.name === "selectedIndex") {
      setSelectedIndex(args.value);
    }
  };

  const itemComponent = () => {
    switch (selectedIndex) {
      case 0:
        return <AccountDetails accountDetails={accountDetails} />;

      case 1:
        return (
          <Suspense fallback={<LoadPanel visible />}>
            <ProfileDetails profileDetails={accountDetails?.profile} />
          </Suspense>
        );

      case 2:
        return (
          <UserJobs
            jobs={accountDetails?.profile?.jobs}
            isProfile={!!accountDetails?.profile}
          />
        );

      default:
        return <AccountDetails accountDetails={accountDetails} />;
    }
  };

  return (
    <>
      {isLoading && <LoadPanel visible />}
      <div className={"content-block"}>
        <form
          className={
            "flex items-center sm:space-x-5 sm:space-y-0 space-y-4 sm:flex-row flex-col"
          }
          onSubmit={getAccountDetails}
        >
          <TextBox
            label='User Account Code'
            className='lg:w-5/12 md:w-2/3 w-full'
            value={accountCode}
            onValueChanged={(e) => {
              if (!e.value) {
                setAccountDetails(null);
              }

              setAccountCode(e.value);
            }}
            valueChangeEvent='keyup'
            showClearButton
          />
          <Button
            text='Search Account'
            type='default'
            disabled={!accountCode.trim()}
            useSubmitBehavior
          />
        </form>
      </div>
      {accountDetails ? (
        <>
          <div className={"content-block pt-5"}>
            <TabPanel
              items={items}
              selectedIndex={selectedIndex}
              onOptionChanged={onSelectionChanged}
              animationEnabled={false}
              itemComponent={itemComponent}
              loop
              swipeEnabled
            />
          </div>
        </>
      ) : (
        <div></div>
      )}
    </>
  );
}
