import React from "react";
import { ManageJobs } from "../../components";

export default function JobsPage() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Manage Jobs</h2>
      <ManageJobs />
    </React.Fragment>
  );
}
