import React, { useState } from "react";
import Form, { SimpleItem, GroupItem, ButtonItem } from "devextreme-react/form";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Lookup,
  MasterDetail,
} from "devextreme-react/data-grid";
import { Button, LoadPanel } from "devextreme-react";
import Pagination from "../common/Pagination";
import { useAuth } from "../../contexts/auth";
import AccountService from "../../service/accountsService";
import { showNotification } from "../../utils/notification";
import { alert, confirm } from "devextreme/ui/dialog";
import { statusData } from "../../constants/data";
import UserDocs from "./user/UserDocs";

export default function ManagePrivatePersonProfile() {
  const [formData] = useState({
    profileStatus: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [profileData, setProfileData] = useState(null);
  const { token } = useAuth();

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!formData.profileStatus) {
      alert("Please apply at least one filter", "Notification");
      return;
    }

    getUsersProfile();
  };

  const getUsersProfile = async (page) => {
    try {
      setIsLoading(true);
      const {
        data: { data },
      } = await AccountService.getPrivatePersonProfile(
        {
          ...formData,
          page: page ? page + 1 : 1,
        },
        token
      );
      if (page || page === 0) {
        setCurrentPage(page);
      }
      setProfileData(data);
      setIsLoading(false);
    } catch (error) {
      showNotification(error, "error");
      setIsLoading(false);
    }
  };

  const updateProfileStatus = async (profileStatus, accountCode) => {
    const result = await confirm(
      "Are you sure you want to update the profile status ?",
      "Confirm"
    );
    if (result) {
      try {
        await AccountService.updateProfileStatus(
          {
            profileStatus,
            accountCode,
            accountType: "individual",
          },
          token
        );

        getUsersProfile(currentPage);
      } catch (error) {
        showNotification(error, "error");
      }
    }
  };

  const renderStatusButton = ({ data }) => {
    const accountCode = data.profileCode;

    switch (data.profileStatus) {
      case "pending":
        return (
          <Button
            text='Activate'
            type='success'
            hint='Activate Profile'
            className='w-full'
            onClick={() => updateProfileStatus("active", accountCode)}
          />
        );

      case "de-activated":
        return (
          <Button
            text='Re-activate'
            type='default'
            hint='Re-activate Profile'
            className='w-full'
            onClick={() => updateProfileStatus("active", accountCode)}
          />
        );

      case "active":
        return (
          <Button
            text='De-activate'
            type='danger'
            hint='De-activate Profile'
            className='w-full'
            onClick={() => updateProfileStatus("de-activated", accountCode)}
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <LoadPanel visible />}
      <form className={"content-block"} onSubmit={onSubmit}>
        <h6 className='mb-3'>Apply Filters</h6>
        <Form labelMode='floating' formData={formData} disabled={isLoading}>
          <GroupItem colCount={2}>
            <SimpleItem
              dataField='profileStatus'
              editorType='dxSelectBox'
              editorOptions={{
                dataSource: statusData,
                displayExpr: "display",
                valueExpr: "value",
                searchEnabled: false,
                showClearButton: true,
              }}
            />
          </GroupItem>
          <ButtonItem
            horizontalAlignment='left'
            buttonOptions={{
              text: "Search",
              type: "default",
              useSubmitBehavior: true,
            }}
          />
        </Form>
      </form>
      {profileData && profileData.docs ? (
        <div className={"content-block dx-card responsive-paddings !mt-8"}>
          <DataGrid
            dataSource={profileData.docs}
            showBorders
            columnAutoWidth
            showColumnLines
            showRowLines
            columnHidingEnabled
            rowAlternationEnabled
            allowColumnResizing
            wordWrapEnabled
            cellHintEnabled
            focusedRowEnabled
            keyExpr='id'
          >
            <FilterRow visible={true} />
            <SearchPanel visible={true} />
            <HeaderFilter visible={true} />
            <ColumnChooser enabled={true} mode='select' allowSearch={true} />
            <Column dataField='profileCode' caption='Account Code' />
            <Column dataField='profileStatus'>
              <Lookup
                dataSource={statusData}
                displayExpr='display'
                valueExpr='value'
              />
            </Column>
            <Column dataField='firstName' />
            <Column dataField='lastName' visible={false} />
            <Column dataField='phone' visible={false} />
            <Column dataField='email' visible={false} />
            <Column
              cellRender={renderStatusButton}
              alignment='center'
              showInColumnChooser={false}
            />
            <MasterDetail enabled component={UserDocs} />
          </DataGrid>

          {profileData.totalDocs > 0 && (
            <div className='mt-9'>
              <Pagination
                currentPage={currentPage}
                setCurrentPage={(page) => {
                  getUsersProfile(page);
                }}
                totalPages={profileData.totalPages}
              />
            </div>
          )}
        </div>
      ) : null}
    </>
  );
}
