import React from "react";
import Form, { SimpleItem, GroupItem } from "devextreme-react/form";
import { colCountByScreen } from "../../../constants";
import avatar from "../../../images/avatar.png";
import { statusData } from "../../../constants/data";

export default function AccountDetails({ accountDetails }) {
  return (
    <>
      <div className={"py-8"}>
        <img
          className='inline object-cover w-32 h-32 mr-2 rounded-lg'
          src={accountDetails.profilePicUrl || avatar}
          alt='Profile'
          loading='lazy'
        />
      </div>

      <div className={"dx-card responsive-paddings"}>
        <Form formData={accountDetails} labelMode='floating' readOnly>
          <GroupItem
            caption='Account Details'
            colCountByScreen={colCountByScreen}
          >
            <SimpleItem dataField='accountCode' />
            <SimpleItem
              dataField='accountStatus'
              editorType='dxSelectBox'
              editorOptions={{
                dataSource: statusData,
                displayExpr: "display",
                valueExpr: "value",
                searchEnabled: false,
                showClearButton: true,
              }}
            />
            <SimpleItem dataField='username' />
            <SimpleItem
              dataField='createdAt'
              editorType='dxDateBox'
              editorOptions={{
                displayFormat: "EEEE, d of MMM, yyyy",
              }}
            />
            <SimpleItem dataField='accountType' />
            <SimpleItem dataField='provider' />
          </GroupItem>
        </Form>
      </div>
    </>
  );
}
