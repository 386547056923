import React, { useState, useRef, useCallback } from "react";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import { useAuth } from "../../contexts/auth";

import "./LoginForm.scss";

export default function LoginForm() {
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ username: "", password: "" });

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { username, password } = formData.current;
      setLoading(true);

      const result = await signIn({ username, password });

      if (!result.success) {
        setLoading(false);
      }
    },
    [signIn]
  );

  return (
    <form className={"login-form"} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading} labelMode='floating'>
        <Item
          dataField={"username"}
          editorType={"dxTextBox"}
          editorOptions={emailEditorOptions}
        >
          <RequiredRule message='Username is required' />
          <Label visible={false} />
        </Item>
        <Item
          dataField={"password"}
          editorType={"dxTextBox"}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message='Password is required' />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={"100%"}
            type={"default"}
            useSubmitBehavior={true}
          >
            <span className='dx-button-text'>
              {loading ? (
                <LoadIndicator width={"24px"} height={"24px"} visible={true} />
              ) : (
                "Sign In"
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
}

const emailEditorOptions = {
  placeholder: "Username",
  mode: "text",
};
const passwordEditorOptions = {
  placeholder: "Password",
  mode: "password",
};
