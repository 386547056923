import React from "react";
import "./profile.scss";
import { CompanyAccountContent } from "../../components";

export default function CompanyAccount() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Company Account</h2>

      <CompanyAccountContent />
    </React.Fragment>
  );
}
