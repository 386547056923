export const navigation = [
  {
    text: "Dashboard",
    path: "/dashboard",
    icon: "pi pi-chart-bar",
  },
  // {
  //   text: "Profile",
  //   path: "/profile",
  //   icon: "pi pi-user-edit",
  // },
  {
    text: "Accounts",
    icon: "pi pi-users",
    items: [
      {
        text: "Manage Profile (Workers)",
        path: "/manage-user-profile",
      },
      {
        text: "User Account",
        path: "/user-account",
      },
      {
        text: "Company Account",
        path: "/company-account",
      },
      {
        text: "Private Person",
        path: "/private-person",
      },
    ],
  },
  {
    text: "Jobs",
    icon: "pi pi-briefcase",
    items: [
      {
        text: "Manage Jobs",
        path: "/manage-jobs",
      },
      {
        text: "Manage Payments",
        path: "/manage-payments",
      },
    ],
  },
];
