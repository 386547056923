import { LoadPanel, Popup, NumberBox, Button } from "devextreme-react";
import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/auth";
import AccountService from "../../service/accountsService";
import JobService from "../../service/jobService";
import { showNotification } from "../../utils/notification";
import JobDataTable from "../jobs/JobDataTable";

export default function Payments() {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [jobsData, setJobData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [paymentMade, setPaymentMade] = useState(null);
  const [jobInfo, setJobInfo] = useState({
    jobId: "",
    invoiceAmount: 0,
  });

  const { token } = useAuth();

  useEffect(() => {
    getAllJobs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmPayment = async () => {
    try {
      setIsLoading(true);
      await JobService.confirmPayment(jobInfo.jobId, paymentMade, token);
      showNotification("Payment confirmed", "success");
      setIsLoading(false);
      handleClose();
      getAllJobs(currentPage);
    } catch (error) {
      showNotification(error);
    }
  };

  const handleConfirmPayment = (info) => {
    setJobInfo(info);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setPaymentMade("");
    setJobInfo({
      jobId: "",
      invoiceAmount: 0,
    });
  };

  const getAllJobs = async (page) => {
    try {
      setIsLoading(true);
      const {
        data: { data },
      } = await AccountService.getAllJobs(
        {
          status: "paid",
          page: page ? page + 1 : 1,
        },
        token
      );
      if (page || page === 0) {
        setCurrentPage(page);
      }
      setJobData(data);
      setIsLoading(false);
    } catch (error) {
      showNotification(error, "error");
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <LoadPanel visible />}
      {jobsData && jobsData.docs ? (
        <div className={"content-block dx-card responsive-paddings"}>
          <JobDataTable
            dataSource={jobsData.docs}
            currentPage={currentPage}
            status={"paid"}
            totalPages={jobsData.totalPages}
            setCurrentPage={(page) => {
              getAllJobs(page);
            }}
            confirmPayment={handleConfirmPayment}
            getAllJobs={() => getAllJobs(currentPage)}
          />
        </div>
      ) : null}

      <Popup
        visible={isOpen}
        onHiding={handleClose}
        title='Payment Amount'
        disabled={isLoading}
        showCloseButton
      >
        <div>
          <span className='mb-3 inline-block'>
            <strong>Invoice amount:</strong> {jobInfo.invoiceAmount}
          </span>
          <NumberBox
            label='Amount paid'
            labelMode='floating'
            min={1}
            value={paymentMade}
            onValueChanged={({ value }) => setPaymentMade(value)}
            valueChangeEvent='keyup'
            showSpinButtons
            showClearButton
          />
          <small>Please enter amount paid by employer</small>

          <div className='mt-4'>
            <Button
              text='Confirm payment'
              type='default'
              disabled={!paymentMade}
              onClick={confirmPayment}
            />
          </div>
        </div>
      </Popup>
    </>
  );
}
