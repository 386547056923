import React from "react";

export default function Alert({ title, message }) {
  return (
    <div className='overflow-hidden leading-normal rounded-lg' role='alert'>
      <p className='px-4 py-3 font-bold text-lg text-purple-100 bg-sky-600'>
        {title}
      </p>
      <p className='px-4 py-3 text-white bg-[#363640] '>{message}</p>
    </div>
  );
}
