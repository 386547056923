import React from "react";
import "./profile.scss";
import { UserAccountContent } from "../../components";

export default function UserAccount() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>User Account</h2>

      <UserAccountContent />
    </React.Fragment>
  );
}
